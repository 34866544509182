import React, { useEffect } from 'react';

const footerStyle = {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    padding: '10px 20px',
    backgroundColor: '#f1f1f1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: '-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif', // Matching the app's font
};

const linksStyle = {
    display: 'flex',
    gap: '20px',
};

const linkStyle = {
    textDecoration: 'none',
    color: '#333',
    fontSize: '14px',
};

const socialIconsStyle = {
    display: 'flex',
    gap: '15px',
    marginRight: '50px',
};

const iconStyle = {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
};

const copyrightStyle = {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-57%)',
    fontSize: '14px',
    color: '#333',
};

const Footer = () => {
    useEffect(() => {
        // Define the media query styles to hide copyright text on small screens
        const styles = `
            @media (max-width: 600px) {
                div[style*="left: 50%"] {
                    display: none; // Hide copyright text when the screen is less than 600px wide
                }
            }
        `;

        // Inject the media query styles into the document head
        const styleSheet = document.createElement('style');
        styleSheet.type = 'text/css';
        styleSheet.innerText = styles;
        document.head.appendChild(styleSheet);

        // Cleanup function to remove the injected styles when component unmounts
        return () => {
            document.head.removeChild(styleSheet);
        };
    }, []);

    return (
        <footer style={footerStyle}>
            <div style={linksStyle}>
                <a href="/privacy" style={linkStyle}>Privacy Policy</a>
                <a href="/terms" style={linkStyle}>Terms & Conditions</a>
            </div>
            <div style={copyrightStyle}>
                © 2024 Ticker In Time. All rights reserved.
            </div>
            <div style={socialIconsStyle}>
                <a href="https://x.com/tickerintime" target="_blank" rel="noopener noreferrer">
                    <img src="/icons/twitter.svg" alt="Twitter" style={iconStyle} />
                </a>
                <a href="https://www.facebook.com/people/Ticker-In-Time/61565430548943" target="_blank" rel="noopener noreferrer">
                    <img src="/icons/facebook.svg" alt="Facebook" style={iconStyle} />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
