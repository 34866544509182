// utils/analytics.js

export const track = (event_name, event_category, event_label) => {
    if (window.gtag) {
        window.gtag('event', event_name, {
            event_category: event_category,
            event_label: event_label,
            value: 1
        });
    }
};
