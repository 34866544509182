// SubscribePopup.js
import React, { useState, useEffect } from 'react';
import EventManager from '../utils/EventManager'; // Import EventManager

const popupOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1001,
};

const popupStyle = {
    width: '400px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
};

const popupImageStyle = {
    width: '30%',
    height: '30%',
    objectFit: 'cover',
    paddingTop: '50px',
    display: 'block', // This ensures the image is treated as a block element for centering
    margin: '0 auto'  // Centers the image horizontally
};

const popupContentStyle = {
    padding: '20px',
};

const SubscribePopup = ({ onSubscribe }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState('');

    const openPopup = () => {
        setIsOpen(true);
    };
    
    useEffect(() => {
        // Register the event to open the popup
        EventManager.register('open_subscribe_popup', openPopup);

        // Cleanup: Unregister event listener on component unmount
        return () => {
            EventManager.unregister('open_subscribe_popup', openPopup);
        };
    }, []);

    const closePopup = () => {
        setIsOpen(false);
    };

    const handleSubscribe = () => {
        onSubscribe(email);
        closePopup();
    };

    if (!isOpen) return null;

    return (
        <div style={popupOverlayStyle} onClick={closePopup}>
            <div style={popupStyle} onClick={(e) => e.stopPropagation()}>
                <img src="mail.jpg" alt="Mailing List" style={popupImageStyle} />
                <div style={popupContentStyle}>
                    <h2>Join Our Mailing List</h2>
                    <p>Stay informed with insights into historical stock trends and market patterns!</p>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        style={{ width: '95%', padding: '8px', margin: '10px 0' }}
                    />
                    <button onClick={handleSubscribe} style={{ width: '100%', padding: '10px', background: '#333', color: '#fff', border: 'none', cursor: 'pointer' }}>
                        Subscribe
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SubscribePopup;
