import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../config';
import { getProfitScoreColor, getOrdinalSuffix } from '../utils/colorUtils';
import { appStyle, cardStyle, cardContainerStyle } from '../styles/styles';
import { alignProperty } from '@mui/material/styles/cssUtils';

const ForexView = () => {
    const [forexData, setForexData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchForexData = async () => {
            try {
                const response = await axios.post(`${BASE_URL}/stats`, { group: 'forex' });
                setForexData(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch forex data');
                setLoading(false);
            }
        };

        fetchForexData();
    }, []);

    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(6, 1fr)', // 4 items in a row
        gap: '20px',
        padding: '20px',
        width: '80%',
        margin: '0 auto',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const boxStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '120px',
        borderRadius: '10px',
        padding: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const getCurrentDayAndMonth = () => {
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.toLocaleString('default', { month: 'long' });
        return `${day}${getOrdinalSuffix(day)} ${month}`;
    };

    return (
        <div style={appStyle}>
            <h1 style={{ textAlign: 'center', fontSize: '2.5em', marginBottom: '0px' }}>Forex Profit Scores</h1>
            <p style={{ textAlign: 'center', fontSize: '1.8em', marginTop: '3px', marginBottom: '30px', color: '#666' }}>
                {getCurrentDayAndMonth()}
            </p>
            <p style={{ textAlign: 'center', fontSize: '1.2em', marginTop: '0px', marginBottom: '40px', color: '#666', maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
    This grid displays the profit scores for various forex pairs, providing a quick overview of their performance. 
    Each box represents a currency pair, showing its symbol and the corresponding profit score.
    The profit score indicates the likelihood of the currency pair's price increasing or decreasing, 
    helping you identify potential trading opportunities. A higher score suggests a greater probability of price appreciation, 
    making it a valuable metric for your trading decisions.
</p>
            <div style={gridStyle}>
                {forexData.map((item, index) => (
                    <div key={index} style={{
                        ...boxStyle,
                        backgroundColor: getProfitScoreColor(item.score)
                    }}>
                        <div style={{ fontSize: '1.1em', fontWeight: 'bold', marginBottom: '10px' }}>{item.symbol}</div>
                        <div style={{ fontSize: '2.1em',  }}>{item.score}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ForexView;