import React, { useEffect, useState } from 'react';
import Card from '../components/Card';
import ProfitScoreCard from '../components/ProfitScoreCard';
import PrevDayChartCard from '../components/PrevDayChartCard';
import Calendar from '../components/Calendar';
import { cardContainerStyle, appStyle, deailViewStyle } from '../styles/styles';
import { getOrdinalSuffix } from '../utils/colorUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../config';
import EventManager from '../utils/EventManager';
import Timeline from '../components/Timeline';

const DetailsView = ({
    symbol, setSymbol, data, setData, error, setError, boxSize, setBoxSize, tooltipVisible, setTooltipVisible
}) => {
    const [loading, setLoading] = useState(false); // New loading state
    const navigate = useNavigate();
    const location = useLocation();

    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonthName = currentDate.toLocaleString('default', { month: 'long' });
    const dayWithSuffix = `${currentDay}${getOrdinalSuffix(currentDay)}`;
    const displayDate = `${dayWithSuffix} ${currentMonthName}`;

    const currDayAllDp = data?.curr_day_all_p?.dp ?? 0;
    const currDayAllStd = data?.curr_day_all_p?.std ?? 0;
    const currDayMonthDp = data?.curr_day_month_p?.dp ?? 0;
    const currDayMonthStd = data?.curr_day_month_p?.std ?? 0;
    const currMonthDp = data?.curr_month?.dp ?? 0;
    const currMonthStd = data?.curr_month?.std ?? 0;
    const profitScore = data?.profit_score ?? 0;
    const companyName = data?.company ?? '';
    const currPrice = data?.curr_price ?? 0;
    const currDp = data?.curr_dp ?? 0;
    const startDate = data?.start_date ?? '';
    const endDate = data?.end_date ?? '';

    const [isInWatchlist, setIsInWatchlist] = useState(false);

    const FilledStar = () => (
        <svg width="32" height="32" viewBox="0 0 24 24" fill="#fcd72b" stroke="#33333333" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 .587l3.668 7.429L24 9.507l-6 5.833L19.335 24 12 20.181 4.665 24 6 15.34 0 9.507l8.332-1.491z" />
        </svg>
    );

    const OutlineStar = () => (
        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#333333" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 .587l3.668 7.429L24 9.507l-6 5.833L19.335 24 12 20.181 4.665 24 6 15.34 0 9.507l8.332-1.491z" />
        </svg>
    );

    useEffect(() => {
        const watchlist = JSON.parse(localStorage.getItem('watchlist')) || [];
        setIsInWatchlist(watchlist.includes(symbol));
    }, [symbol]);

    const handleAlertUpdate = async (symbols) => {
        const email = localStorage.getItem('subscribedEmail');
        if (email) {
            try {
                console.log(symbols);
                await axios.post(`${BASE_URL}/alert_update`, {
                    email,
                    symbols: symbols
                });
            } catch (error) {
                console.error('Error updating alert preferences:', error);
            }
        }
    };

    const toggleWatchlist = () => {
        let watchlist = JSON.parse(localStorage.getItem('watchlist')) || [];

        // Check if the symbol is already in the watchlist
        const isInWatchlist = watchlist.includes(symbol);

        if (isInWatchlist) {
            // Remove the symbol from the watchlist if it's already there
            watchlist = watchlist.filter(item => item !== symbol);
        } else {
            // Add the symbol to the watchlist
            if (watchlist.length < 20) {
                watchlist.push(symbol);
            } else {
                alert("Watchlist is limited to 20 symbols.");
            }
        }

        // Save the updated watchlist back to localStorage as a JSON string
        localStorage.setItem('watchlist', JSON.stringify(watchlist));
        setIsInWatchlist(!isInWatchlist);  // Toggle the UI state

        handleAlertUpdate(watchlist);

        EventManager.trigger('watchlist_updated', null);
    };

    const fetchData = (symbolToFetch) => {
        setLoading(true); // Set loading to true when data is being fetched
        const payload = { symbol: symbolToFetch };

        return axios.post(`${BASE_URL}/data`, payload)
            .then(response => {
                if (!response.data || Object.keys(response.data).length === 0) {
                    setError('No data found for this symbol');
                    setLoading(false); // Stop loading
                    return;
                }

                setData(response.data);
                setError(null);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                if (error.response && error.response.status === 404) {
                    setError('Symbol not found');
                } else {
                    setError('Failed to fetch data');
                }
            })
            .finally(() => {
                setLoading(false); // Stop loading after the request finishes
            });
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const symbolFromURL = params.get('symbol');
        if (symbolFromURL) {
            setSymbol(symbolFromURL);
            fetchData(symbolFromURL);
        } else {
            navigate('/');
        }
    }, [location.search, setSymbol]);

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 768;
            if (isMobile) {
                const newBoxSize = Math.floor(window.innerWidth * 1.1 / 12);
                setBoxSize(newBoxSize);
            } else {
                const newBoxSize = Math.floor(window.innerWidth * 0.8 / 31);
                setBoxSize(newBoxSize);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial box size

        return () => window.removeEventListener('resize', handleResize);
    }, [setBoxSize]);

    return (
        <div style={deailViewStyle}>
            {error && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <img src="ic_not_found.svg" alt="Error" style={{ width: '100px', marginBottom: '10px' }} />
                    <p style={{ color: 'black', fontSize: '20px' }}>{error}</p>
                </div>
            )}


            {loading ? (
                // Display loading spinner when loading
                <div style={{ textAlign: 'center', marginTop: '0px' }}>
                    <div className="spinner" style={{ 
                        border: '4px solid rgba(0, 0, 0, 0.1)', 
                        borderRadius: '50%', 
                        borderTop: '4px solid #3498db', 
                        width: '50px', 
                        height: '50px', 
                        animation: 'spin 2s linear infinite',
                        margin: '0 auto' // Center the spinner horizontally
                    }}></div>
                    <p style={{ marginTop: '20px', fontSize: '18px', color: '#666' }}>Crunching the numbers, please wait...</p>
                </div>
            ) : (
                data && (
                    <div style={{ marginTop: '20px', width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80%', margin: '0 auto' }}>
                            <div style={{ textAlign: 'left' }}>
                                <h2 style={{ fontSize: '16px', margin: 0, color: 'rgba(0, 0, 0, 0.75)' }}>{data.exchange.toUpperCase()}</h2>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span onClick={toggleWatchlist} style={{ marginLeft: '-50px', marginRight: '15px', cursor: 'pointer' }}>
                                        {isInWatchlist ? <FilledStar /> : <OutlineStar />}
                                    </span>
                                    <h1 style={{ fontSize: '48px', margin: 0 }}>{data.symbol.toUpperCase()}</h1>
                                    {/* Move the star icon next to the symbol */}
                                    
                                </div>
                                <h3 style={{ fontSize: '24px', marginTop: '0px', color: '#666' }}>{companyName}</h3>
                            </div>
                            <div style={{ textAlign: 'right'}}>
                                <h1 style={{ fontSize: '14px', margin: 0 }}>STATISTICS RANGE</h1>
                                <h3 style={{ fontSize: '24px', marginTop: '5px', color: '#666' }}>{startDate} - {endDate}</h3>
                            </div>
                        </div>


                        <div style={{ textAlign: 'center', margin: '-20px 0px 20px', fontSize: '24px', color: '#333' }}>
                            {displayDate}
                        </div>

                        <div style={cardContainerStyle}>
                            <Card
                                title={`Avg. ${currentDay}${getOrdinalSuffix(currentDay)} Day Return`}
                                subtitle="across all months"
                                value={currDayAllDp.toFixed(2)}
                                std={currDayAllStd.toFixed(2)}
                                tooltip={`The mean percent change for the ${currentDay}${getOrdinalSuffix(currentDay)} day of the month across all months.`}
                                tooltipVisible={tooltipVisible[1]}
                                setTooltipVisible={(visible) => setTooltipVisible({ ...tooltipVisible, 1: visible })}
                            />
                            <Card
                                title={`Avg. ${currentDay}${getOrdinalSuffix(currentDay)} Day Return`}
                                subtitle={currentMonthName}
                                value={currDayMonthDp.toFixed(2)}
                                std={currDayMonthStd.toFixed(2)}
                                tooltip={`The mean percent change for the ${currentDay}${getOrdinalSuffix(currentDay)} day of the month within the current month.`}
                                tooltipVisible={tooltipVisible[2]}
                                setTooltipVisible={(visible) => setTooltipVisible({ ...tooltipVisible, 2: visible })}
                            />
                            <Card
                                title={`Avg. Month Return`}
                                subtitle={currentMonthName}
                                value={currMonthDp.toFixed(2)}
                                std={currMonthStd.toFixed(2)}
                                tooltip="The average percent change for the current month."
                                tooltipVisible={tooltipVisible[3]}
                                setTooltipVisible={(visible) => setTooltipVisible({ ...tooltipVisible, 3: visible })}
                            />
                            
                            <ProfitScoreCard
                                title="Profit Score"
                                subtitle="[0-100]"
                                value={profitScore}
                                tooltip={`Profit score is calculated based on the probability for \$${data.symbol.toUpperCase()} to rise or fall today.`}
                                tooltipVisible={tooltipVisible[4]}
                                setTooltipVisible={(visible) => setTooltipVisible({ ...tooltipVisible, 4: visible })}
                            />
                        </div>

{/*
                        <h3 style={{ textAlign: 'left', marginTop: '40px', marginBottom: '10px', marginLeft: '10%' }}>Last 7 days returns compared to mean</h3>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '80%', margin: '0 auto' }}>
                            <PrevDayChartCard
                                prev_days={data.prev_days.slice().reverse()}
                                tooltip="This chart shows the real daily return for the last 7 days compared to the average daily return for this day across all months."
                                tooltipVisible={tooltipVisible[5]}
                                setTooltipVisible={(visible) => setTooltipVisible({ ...tooltipVisible, 5: visible })}
                            />
                        </div>
*/}

                        <Calendar data={data.months} boxSize={boxSize} />
                    </div>
                )
            )}
        </div>
    );
};

export default DetailsView;
