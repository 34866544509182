import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import axios from 'axios';
import { BASE_URL } from '../config';
import { inputStyle, buttonStyle, errorTextStyle, quickSearchButtonStyle, dropdownStyle, dropdownItemStyle, dropdownItemSymbolStyle, dropdownItemExchangeStyle, dropdownHeaderStyle } from '../styles/styles';
import { track } from '../utils/analytics'; // Adjust the path as needed


let cancelToken;

const SearchBar = ({ symbol, setSymbol }) => {
    const [suggestions, setSuggestions] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [disableInputChange, setDisableInputChange] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [typingTimeout, setTypingTimeout] = useState(null);
    
    const navigate = useNavigate(); // Initialize navigate hook for navigation

    const loaderStyle = {
        border: '2px solid #f3f3f3',
        borderTop: '2px solid #3498db',
        borderRadius: '50%',
        width: '16px',
        height: '16px',
        animation: 'spin 1s linear infinite',
    };

    const addGlobalStyles = () => {
        const style = document.createElement('style');
        style.innerHTML = `
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
        `;
        document.head.appendChild(style);
    };

    useEffect(() => {
        addGlobalStyles();
    }, []);

    const handleInputChange = (e) => {
        if (disableInputChange || loading) return;

        const value = e.target.value;
        setSymbol(value);

        if (value.length > 1) {
            if (typingTimeout) clearTimeout(typingTimeout);

            setTypingTimeout(setTimeout(() => {
                if (cancelToken) cancelToken.cancel("Operation canceled due to new request.");

                cancelToken = axios.CancelToken.source();
                const payload = { query: value };

                axios.post(`${BASE_URL}/search`, payload, { cancelToken: cancelToken.token })
                    .then(response => {
                        if (response.data.length > 0) {
                            setSuggestions(response.data);
                            setShowDropdown(true);
                            setError(null);
                        } else {
                            setError('No results found.');
                            setShowDropdown(false);
                        }
                    })
                    .catch((error) => {
                        if (!axios.isCancel(error)) {
                            setError('Error fetching suggestions.');
                            setShowDropdown(false);
                        }
                    });
            }, 500));
        } else {
            setShowDropdown(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setDisableInputChange(true);
        setShowDropdown(false);
        setLoading(true);
        setSymbol(suggestion.exchange + ':' + suggestion.symbol);
        setError(null);

        // Directly navigate to the details page with the selected symbol
        navigate(`/details?symbol=${encodeURIComponent(suggestion.exchange + ':' + suggestion.symbol)}`);
        setLoading(false);
        setDisableInputChange(false);
    };

    const handleQuickSearch = (quickSymbol) => {
        track('quick_search', 'Search', quickSymbol);

        setDisableInputChange(true);
        setShowDropdown(false);
        setLoading(true);
        setSymbol(quickSymbol);
        setError(null);

        // Directly navigate to the details page with the quick search symbol
        navigate(`/details?symbol=${encodeURIComponent(quickSymbol)}`);
        setLoading(false);
        setDisableInputChange(false);
    };

    const handleGoClick = () => {
        if (symbol.trim() === '') {
            setError('Missing symbol');
            track('error', 'Search', 'Missing symbol');
        } else {
            setLoading(true); // Show loader while validating
            axios.post(`${BASE_URL}/valid`, { symbol })
                .then(response => {
                    const { valid, fix } = response.data;
                    if (valid) {
                        setDisableInputChange(true);
                        setShowDropdown(false);
                        setError(null);

                        // Navigate to the details page
                        navigate(`/details?symbol=${encodeURIComponent(symbol)}`);
                        setLoading(false);
                        setDisableInputChange(false);
                    } else if (!valid && fix) {
                        setSymbol(fix);
                        setDisableInputChange(true);
                        setShowDropdown(false);
                        setError(null);

                        track('search', 'Search', fix);

                        // Navigate to the details page with the fixed symbol
                        navigate(`/details?symbol=${encodeURIComponent(fix)}`);
                        setLoading(false);
                        setDisableInputChange(false);
                    } else {
                        setError('Invalid symbol. Please enter a valid one.');
                        track('error', 'Search', 'Invalid symbol');
                        setLoading(false);
                    }
                })
                .catch(() => {
                    setError('Error validating the symbol.');
                    track('error', 'Search', 'Validation error');
                    setLoading(false);
                });
        }
    };

    return (
        <div style={{ position: 'relative', width: '80%', maxWidth: '600px', marginBottom: '20px', textAlign: 'left' }}>
            
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                    type="text"
                    value={symbol}
                    onChange={handleInputChange}
                    placeholder="Symbol, eg. AAPL"
                    disabled={disableInputChange || loading}
                    style={inputStyle}
                />
                <button
                    onClick={handleGoClick}
                    disabled={disableInputChange || loading}
                    style={buttonStyle}
                >
                    {loading ? (
                        <div style={loaderStyle}></div> 
                    ) : 'Go'}
                </button>
            </div>

            {showDropdown && (
                <ul style={dropdownStyle}>
                    <li style={dropdownHeaderStyle}>
                        <span style={{ textAlign: 'left', flex: 1 }}>Symbol</span>
                        <span style={{ textAlign: 'right', flex: 1 }}>Exchange</span>
                    </li>
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                            style={dropdownItemStyle}
                        >
                            <span style={dropdownItemSymbolStyle}>{suggestion.symbol}</span>
                            <span style={dropdownItemExchangeStyle}>{suggestion.exchange}</span>
                        </li>
                    ))}
                </ul>
            )}

            {error && <p style={errorTextStyle}>{error}</p>}

            <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', marginTop: '20px', gap: '0px' }}>
                {['NASDAQ:AAPL', 'BINANCE:BTCUSDT', 'PEPPERSTONE:EURUSD'].map((preset, index) => (
                    <button
                        key={index}
                        onClick={() => handleQuickSearch(preset)}
                        style={quickSearchButtonStyle}
                        disabled={loading}
                    >
                        {preset}
                    </button>
                ))}
            </div>

        </div>
    );
};

export default SearchBar;
