export const appStyle = {
    fontFamily: '-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '98vh',
    backgroundColor: '#f5f5f5',
    position: 'relative',
};

export const deailViewStyle = {
    paddingTop: '50px',
    paddingBottom: '30px',
    fontFamily: '-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '98vh',
    backgroundColor: '#f5f5f5',
    position: 'relative',
};

export const cardContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',  // Enable wrapping for mobile
    marginBottom: '20px',
    width: '100%',
};

// Additional mobile-specific style
export const cardContainerMobileStyle = {
    flexDirection: 'column',  // Stack cards vertically for mobile
    alignItems: 'center',  // Center cards horizontally
};


export const inputStyle = {
    padding: '15px',
    fontSize: '18px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    width: '100%',
};

export const buttonStyle = {
    position: 'absolute',
    right: '0px',
    top: 0,
    bottom: 0,
    padding: '0 20px',
    fontSize: '18px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007BFF',
    color: 'white',
    cursor: 'pointer',
    height: '53px',
    transition: 'background-color 0.3s',
};

export const hintTextStyle = {
    fontSize: '14px',
    color: '#777',
    marginTop: '5px',
};

export const quickSearchButtonStyle = {
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '20px',
    padding: '10px 20px',
    marginRight: '10px',
    marginBottom: '10px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
};

export const dropdownStyle = {
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '4px',
    listStyleType: 'none',
    padding: '0',
    marginTop: '5px',
    overflowY: 'auto',
    position: 'absolute',  // Make it overlay
    zIndex: 1000,          // Ensure it's above other content
    width: '100%',         // Take up full width of the container
    maxHeight: '300px',  // Increased height to allow more items to be visible (adjust as needed)
};

export const dropdownHeaderStyle = {
    padding: '10px',
    fontWeight: 'bold',
    backgroundColor: '#f9f9f9',
    fontSize: '12px',
    borderBottom: '1px solid #ccc',
    display: 'flex',
    justifyContent: 'space-between',
};

export const dropdownItemStyle = {
    padding: '10px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
};

export const dropdownItemSymbolStyle = {
    textAlign: 'left',
    flex: 1,
    fontWeight: 'bold',
};

export const dropdownItemExchangeStyle = {
    textAlign: 'right',
    flex: 1,
    color: '#888',
};


export const cardStyle = {
    position: 'relative',
    backgroundColor: '#fff',
    padding: '20px',
    margin: '10px',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    minWidth: '150px',
    flex: '1',
    maxWidth: '200px',
};

export const cardCanvasStyle = {
    position: 'relative',
    backgroundColor: '#fff',
    padding: '0px',
    margin: '10px',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    minWidth: '250px',
    flex: '1',
    maxWidth: '200px',
};

export const cardChartStyle = {
    position: 'relative',
    backgroundColor: '#fff',
    
    height: '280px',
    padding: '0px',
    margin: '10px',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    
    flex: '1',
    
};

export const infoIconStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    width: '18px',
    height: '18px',
    color: 'darkgray',
};

export const tooltipStyle = {
    visibility: 'hidden',
    width: '200px',
    backgroundColor: '#555',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '5px',
    padding: '18px',
    position: 'absolute',
    zIndex: '1',
    bottom: '110%', /* Adjusted to move the tooltip down */
    left: '50%',
    marginLeft: '-100px',
    opacity: '0',
    transition: 'opacity 0.3s',
};

export const tooltipVisibleStyle = {
    visibility: 'visible',
    opacity: '1',
};

export const errorTextStyle = {
    color: 'red',
    fontSize: '14px',
    marginBottom: '5px',
    marginTop: '10px',
};
