// src/pages/PrivacyPolicy.js
import React from 'react';
import PageLayout from '../components/PageLayout';

const PrivacyPolicy = () => {
    return (
        <PageLayout title="Privacy Policy">
            <p>
                At ticker-in-time, we respect your privacy and are committed to protecting any personal information you may provide while using our site. 
                This privacy policy outlines how we collect, use, and protect your information.
            </p>

            {/* Divider line */}
            <hr style={{ margin: '20px 0', border: 'none', borderTop: '2px solid #ccc' }} />

            <h2>Information We Collect</h2>
            <p>
                We do not collect any personal information from users. We do not ask for or store your name, email address, or any other identifying information.
            </p>

            <h2>Analytics</h2>
            <p>
                We use analytics tools to collect anonymized data regarding the usage of our site, such as page views, time spent on different parts of the site, and interactions with user interface elements. This data helps us improve the user experience and optimize the functionality of the site.
            </p>

            <h2>Data Sharing</h2>
            <p>
                We do not share any user data with third parties. Any information collected is strictly for internal analysis and improvement of our services.
            </p>

            <h2>Security</h2>
            <p>
                While we do not collect personal data, we are committed to ensuring the security of the site and protecting the analytics data we collect. We use standard security measures to safeguard our systems from unauthorized access.
            </p>

            <h2>Changes to This Privacy Policy</h2>
            <p>
                We may update this privacy policy from time to time. If we make any changes, we will post the updated policy on this page. We encourage users to review this page periodically to stay informed about how we protect their privacy.
            </p>

            {/* Divider line */}
            <hr style={{ margin: '20px 0', border: 'none', borderTop: '2px solid #ccc' }} />

            <p>
                If you have any questions or concerns about this privacy policy, please contact us at 
                <a href="mailto:support@tickerintime.com" style={{ textDecoration: 'none', color: '#007BFF', marginLeft: '5px' }}>
                    support@tickerintime.com
                </a>.
            </p>
        </PageLayout>
    );
};

export default PrivacyPolicy;
