import React, { useState, useEffect } from 'react';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import Watchlist from './Watchlist';  // Import the Watchlist component
import EventManager from '../utils/EventManager';


const headerStyle = {
    position: 'fixed',
    top: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 20px',
    fontFamily: '-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif', // Matching the app's font
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // Transparent white
    zIndex: 1000,
    backdropFilter: 'blur(5px)',
};

const navLinksStyle = {
    display: 'flex',
    gap: '20px',
    paddingTop: '6px'
};

const linkStyle = {
    textDecoration: 'none',
    color: '#333',
    fontWeight: 'bold',
    fontSize: '16px',
    cursor: 'pointer',
};

const menuButtonStyle = {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    
    marginRight: '40px',
    color: '#333',
    marginLeft: 'auto', // Move the button to the far right
};

const subscribeButtonContainerStyle = {
    flex: 1, // Take up all available space
    display: 'flex',
    justifyContent: 'center', // Center the subscribe button
};

const subscribeButtonStyle = {
    border: 'none',
    background: 'none',
    color: '#333',
    fontWeight: 'bold',
    fontSize: '16px',
    cursor: 'pointer',
     textDecoration: 'underline',
    marginRight: '100px'
};

//<a href="/strategy" style={linkStyle}>STRATEGY</a>
const Header = () => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [watchlist, setWatchlist] = useState([]);
    const [isSubscribed, setIsSubscribed] = useState(localStorage.getItem('isSubscribed') === 'true');

    const handleSubscribeClick = () => {
        // Trigger the event to open the popup
        EventManager.trigger('open_subscribe_popup');
    };

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    const updateDrawer = () => {
        const savedWatchlist = JSON.parse(localStorage.getItem('watchlist')) || [];
        const formattedWatchlist = savedWatchlist.map(item => {
            const [exchange, name] = item.split(':');
            return { exchange, name };  // Create a JSON object with 'exchange' and 'name'
        });
        setWatchlist(formattedWatchlist);
    }

    const onSubscribe = () => {
        setIsSubscribed(true);
    }

    // Fetch the watchlist from localStorage when the component mounts
    useEffect(() => {
        updateDrawer();

        const onWatchListUpdated = (data) => {
            updateDrawer();
        };

        const onWatchListToggleReuqest = (data) => {
            toggleDrawer();
        };

        EventManager.register('watchlist_updated', onWatchListUpdated);
        EventManager.register('toggle_watchlist', onWatchListToggleReuqest);
        EventManager.register('on_subscribe', onSubscribe);

        // Cleanup: Unregister event listener on component unmount
        return () => {
            EventManager.unregister('toggle_watchlist', onWatchListToggleReuqest);
            EventManager.unregister('watchlist_updated', onWatchListUpdated);
            EventManager.unregister('on_subscribe', onSubscribe);
        };


    }, []);


    return (
        <header style={headerStyle}>
            <nav style={navLinksStyle}>
                <a href="/" style={linkStyle}>HOME</a>
                <a href="/forex" style={linkStyle}>FOREX</a>
                <a href="/about" style={linkStyle}>ABOUT</a>                

                <Drawer open={isOpen} onClose={toggleDrawer} direction="right" style={{ width: '400px' }}>
                    <Watchlist symbols={watchlist} />  {/* Pass the symbols to Watchlist */}
                </Drawer>

            </nav>


            {/*
            !isSubscribed && (
                <div style={subscribeButtonContainerStyle}>
                    <button onClick={handleSubscribeClick} style={subscribeButtonStyle}>
                        Subscribe for Updates
                    </button>
                </div>
            )
            */
            }

            <button onClick={toggleDrawer} style={menuButtonStyle}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#333">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z" />
                </svg>
            </button>
        </header>
    );
};

export default Header;
