import React, { useState, useEffect } from 'react';
import Tooltip from './Tooltip';
import { cardStyle, infoIconStyle } from '../styles/styles';

const Card = ({ title, subtitle, value, std, tooltip, tooltipVisible, setTooltipVisible }) => {
    // State to manage screen size
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

    // Effect to handle screen resizing
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 600);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Define styles for small screens
    const smallScreenStyle = {
        ...cardStyle,
        minWidth: '100px',
        maxWidth: '150px',
        // Add other styles for small screens as needed
    };

    return (
        <div style={isSmallScreen ? smallScreenStyle : cardStyle}>
            <img
                src="/ic_info.svg"
                alt="info"
                style={infoIconStyle}
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}
            />
            <Tooltip text={tooltip} isVisible={tooltipVisible} />
            <h4 style={{ marginBottom: '-15px', fontSize: '18px', color: '#333' }}>
                {title}
            </h4>
            <h5 style={{ marginBottom: '15px', fontSize: '14px', color: '#888' }}>
                {subtitle}
            </h5>
            <p style={{ fontSize: '38px', margin: '10px 0' }}>{value}%</p>
            <p style={{ fontSize: '16px', color: '#666' }}>±{std}%</p>
        </div>
    );
};

export default Card;
