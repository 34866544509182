// src/pages/About.js
import React from 'react';
import PageLayout from '../components/PageLayout';
// Import Font Awesome icon if using Font Awesome
import { FaEnvelope } from 'react-icons/fa'; // You need to install react-icons package

const About = () => {
    return (
        <PageLayout title="About">
            <p>
                ticker-in-time is your dedicated site for exploring historical stock returns by day and month. All the information 
                presented here is based on past market data, and is provided for educational purposes only. While it can offer 
                insights into patterns and trends, it's important to be cautious when applying this information directly to trading decisions.
            </p>

            <p>
                Our goal is to help you gain a better understanding of what might be expected in future days and months based on similar past behavior. 
                Whether you're an investor or simply curious about market trends, ticker-in-time provides an intuitive way to explore how stocks 
                have performed historically.
            </p>

            <p>
                Thank you for using ticker-in-time as your resource for analyzing historical stock data.
            </p>

            {/* Divider line */}
            <hr style={{ margin: '20px 0', border: 'none', borderTop: '2px solid #ccc' }} />

            <p>
                If you want to connect, give suggestions, or report any bugs, feel free to reach out to us via email at 
                <a href="mailto:support@tickerintime.com" style={{ textDecoration: 'none', color: '#007BFF', marginLeft: '5px' }}>
                    support@tickerintime.com
                </a>.
            </p>
        </PageLayout>
    );
};

export default About;
