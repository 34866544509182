import React, { useEffect } from 'react';
import SearchBar from '../components/SearchBar'; // Assuming SearchBar is in the same directory or adjust the path
import { appStyle } from '../styles/styles';
import SubscribePopup from '../components/SubscribePopup'; // Import the SubscribePopup component
import EventManager from '../utils/EventManager';

function Home({ symbol, setSymbol }) {
	const buttonContainerStyle = {
	    position: 'fixed',
	    bottom: '70px',
	    left: '50%',
	    transform: 'translateX(-50%)',  // Centers the button horizontally
	    zIndex: 100  // Ensures it stays on top of other elements
	};
	
    const handleSubscribe = (email) => {
        // Here you would typically send the email to your backend to register the user
        localStorage.setItem('isSubscribed', 'true');
        EventManager.trigger('on_subscribe');
    };

    return (
        <div style={appStyle}>
            <img src="/logo.png" alt="Logo" style={{ width: '80%', maxWidth: '600px', marginBottom: '40px' }} />
            <SearchBar
                symbol={symbol}
                setSymbol={setSymbol}
            />
            <div style={buttonContainerStyle}>
                <a href="https://www.buymeacoffee.com/tickerintime" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" 
                        alt="Buy Me A Coffee" 
                        style={{ height: '60px', width: '217px' }}
                    />
                </a>
            </div>
            <SubscribePopup onSubscribe={handleSubscribe} />
        </div>
    );
}

export default Home;
