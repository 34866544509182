import React from 'react';
import { tooltipStyle, tooltipVisibleStyle } from '../styles/styles';

const Tooltip = ({ text, isVisible }) => (
    <div style={isVisible ? { ...tooltipStyle, ...tooltipVisibleStyle } : tooltipStyle}>
        {text}
        <div style={{
            position: 'absolute',
            top: '100%',
            left: '50%',
            marginLeft: '-5px',
            borderWidth: '5px',
            borderStyle: 'solid',
            borderColor: '#555 transparent transparent transparent',
        }}></div>
    </div>
);

export default Tooltip;
