// src/pages/TermsAndConditions.js
import React from 'react';
import PageLayout from '../components/PageLayout';

const TermsAndConditions = () => {
    return (
        <PageLayout title="Terms and Conditions">
            <p>
                Welcome to ticker-in-time. By using this website, you agree to comply with and be bound by the following terms and conditions. 
                Please review the following terms carefully. If you do not agree to these terms, you should not use this site.
            </p>

            {/* Divider line */}
            <hr style={{ margin: '20px 0', border: 'none', borderTop: '2px solid #ccc' }} />

            <h2>Educational Purpose Only</h2>
            <p>
                All the information provided on ticker-in-time is for educational and informational purposes only. 
                We do not offer financial advice, and the content on this site should not be interpreted as a recommendation to buy, sell, or hold any security.
            </p>
            <p>
                The historical data and analysis presented on this site is intended to provide a better understanding of how certain stocks and markets have behaved 
                in the past, but it does not predict future results. Past performance is not indicative of future performance, and any decision you make based on the information provided is done at your own risk.
            </p>

            <h2>Trading Disclaimer</h2>
            <p>
                Trading stocks, cryptocurrencies, or any financial instruments involves risk. You can lose more than your initial investment, and you should always perform your own due diligence before making any trading decisions. 
                ticker-in-time does not guarantee any specific outcomes or profits. 
            </p>
            <p>
                The content on this site is not intended as investment advice and should not be treated as such. We encourage users to consult with a qualified financial advisor before engaging in trading or investment activities.
            </p>

            <p>
                Always trade with caution and never invest more than you are willing to lose. Remember, the market can be volatile and unpredictable, and ticker-in-time cannot be held responsible for any financial losses incurred as a result of your trading or investment activities.
            </p>

            {/* Divider line */}
            <hr style={{ margin: '20px 0', border: 'none', borderTop: '2px solid #ccc' }} />

            <h2>Limitation of Liability</h2>
            <p>
                ticker-in-time and its owners, contributors, and affiliates will not be liable for any direct, indirect, incidental, or consequential damages that result from the use of, or the inability to use, the materials on this site. This includes, but is not limited to, loss of profit, investment loss, or damages arising from reliance on any information contained within the site.
            </p>

            <h2>Changes to the Terms and Conditions</h2>
            <p>
                We reserve the right to modify these terms and conditions at any time. If any changes are made, we will update this page accordingly. It is your responsibility to review these terms periodically.
            </p>

            {/* Divider line */}
            <hr style={{ margin: '20px 0', border: 'none', borderTop: '2px solid #ccc' }} />

            <p>
                If you have any questions regarding these terms and conditions, feel free to contact us at 
                <a href="mailto:support@tickerintime.com" style={{ textDecoration: 'none', color: '#007BFF', marginLeft: '5px' }}>
                    support@tickerintime.com
                </a>.
            </p>
        </PageLayout>
    );
};

export default TermsAndConditions;
