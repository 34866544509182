import React, { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const AreaChartComponent = ({ data, baselineData, minBalance, maxBalance }) => {
    const [height, setHeight] = useState(600); 

    useEffect(() => {
        // Trigger a state update (forcing a re-render) after a small delay
        const timer = setTimeout(() => {
            setHeight(601); 
        }, 100);

        return () => clearTimeout(timer);  // Cleanup the timeout on unmount
    }, []);

    // Formatter for X-axis to show only when the year changes
    const formatXAxis = (tick) => {
        const date = new Date(tick);
        return date.getFullYear(); // Show only the year
    };

    // Formatter for Y-axis to abbreviate large numbers (e.g., 1M for million, 1K for thousand)
    const formatYAxis = (tick) => {
        if (tick >= 1000000) {
            return (tick / 1000000).toFixed(1) + 'M'; // Convert to millions and add 'M'
        } else if (tick >= 1000) {
            return (tick / 1000).toFixed(1) + 'K'; // Convert to thousands and add 'K'
        } else {
            return Math.round(tick); // Keep smaller numbers as they are
        }
    };


    return (
        <ResponsiveContainer width="100%" height={height}>
            <AreaChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                {/* X-axis format */}
                <XAxis dataKey="date" tickFormatter={formatXAxis} interval={300} />
                {/* Y-axis format */}
                <YAxis domain={[minBalance, maxBalance]} tickFormatter={formatYAxis} />
                <Tooltip />
                {/* Area for strategy data */}
                <Area
                    type="monotone"
                    dataKey="balance"
                    stroke="#94b3c8"  // Strategy line color
                    fill="#e7eff3"    // Strategy fill color
                    strokeWidth={3}   // Make the line thicker
                    activeDot={{ r: 8 }}
                    name="Strategy"   // Legend label for strategy
                />
                {/* Area for baseline data */}
                <Area
                    type="monotone"
                    dataKey="balance"
                    stroke="#ffd700"  // Yellow color for baseline line
                    fill="rgba(255, 215, 0, 0.3)"  // Light yellow fill for baseline
                    strokeWidth={2}   // Baseline line thickness
                    data={baselineData}  // Baseline data passed in as prop
                    name="Baseline"    // Legend label for baseline
                    dot={false}  // No dots for baseline data
                />
                {/* Legend to distinguish between the strategy and baseline */}
                <Legend 
                    verticalAlign="bottom" 
                    height={26} 
                    wrapperStyle={{ paddingTop: '10px' }} // Add padding above the legend
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default AreaChartComponent;
