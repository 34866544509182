import React from 'react';

const StrategyStatsCard = ({ title, value, subtitle, additionalInfo }) => {
    return (
        <div style={{
            border: '1px solid #ddd',
            borderRadius: '8px',
            padding: '20px',
            width: '240px',
            textAlign: 'center',
            backgroundColor: '#f9f9f9',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            fontFamily: '-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif'
        }}>
            <h3 style={{ fontSize: '18px', marginBottom: '10px', color: '#333' }}>{title}</h3>
            <p style={{ fontSize: '28px', margin: '0', color: '#000' }}>{value}</p>
            {additionalInfo && <p style={{ fontSize: '14px', marginTop: '5px', color: '#666' }}>{additionalInfo}</p>}
        </div>
    );
};

export default StrategyStatsCard;
