import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';
import { appStyle, errorTextStyle } from '../styles/styles';  // Import the styles

const Unsubscribe = () => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    // Helper function to get the email from the query params
    const getEmailFromParams = () => {
        const params = new URLSearchParams(location.search);
        return params.get('email');
    };

    useEffect(() => {
        const email = getEmailFromParams();
        if (!email) {
            setError('Email not provided');
            return;
        }

        // Prepare the payload
        const payload = { email };

        // Call the unsubscribe API
        axios.post(`${BASE_URL}/alert_unsub`, payload)
            .then(response => {
                if (response.data.message) {
                    setMessage('You have successfully unsubscribed.');
                    localStorage.removeItem('subscribedEmail');
                } else if (response.data.error) {
                    setError(response.data.error);
                }
            })
            .catch((error) => {
                setError('Error processing the unsubscription.');
            });
    }, [location]);

    return (
        <div style={appStyle}>  {/* Use appStyle to center content */}
            {message && <p style={{ color: 'green' }}>{message}</p>}
            {error && <p style={errorTextStyle}>{error}</p>}  {/* Reuse errorTextStyle */}
            <button onClick={() => navigate('/')} style={{ ...errorTextStyle, backgroundColor: '#007BFF', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                Back to Home
            </button>
        </div>
    );
};

export default Unsubscribe;
