// utils/colorUtils.js

export const getColor = (dp) => {
    if (dp > 0) return '#079a81'; // Green color
    if (dp < 0) return '#f33645'; // Red color
    return 'transparent'; // No color for zero
};

export const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // Covers 11th to 19th
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
};

export const getProfitScoreColor = (score) => {
    if (score >= 80) return '#079a81';
    if (score >= 60) return '#079a73';
    if (score >= 40) return '#fcd72b';
    if (score >= 20) return '#f55865';
    return '#f33645'
};

export const getProfitScoreDescription = (score) => {
    if (score >= 80) return 'Very Positive';
    if (score >= 60) return 'Positive';
    if (score >= 40) return 'Neutral';
    if (score >= 20) return 'Negative';
    return 'Very Negative';
};