// src/components/PageLayout.js
import React from 'react';

const pageStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'center',
    fontFamily: '-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif',
};

const titleStyle = {
    fontSize: '36px',
    fontWeight: 'bold',
    marginBottom: '20px',
};

const textStyle = {
    fontSize: '20px',
    lineHeight: '1.6',
    textAlign: 'left',  // Align text to the left
    fontFamily: '-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif',
};

const PageLayout = ({ title, children }) => {
    return (
        <div style={pageStyle}>
            <h1 style={titleStyle}>{title}</h1>
            <div style={textStyle}>
                {children}
            </div>
        </div>
    );
};

export default PageLayout;
