import React, { useState, useRef } from 'react';
import { getOrdinalSuffix } from '../utils/colorUtils';

const Calendar = ({ data, boxSize }) => {
    const [tooltip, setTooltip] = useState({ visible: false, content: '', top: 0, left: 0 });
    const containerRef = useRef(null);  // Reference to the container

    const handleMouseEnter = (e, monthName, day, dp, std) => {
        const containerRect = containerRef.current.getBoundingClientRect(); // Get container bounding box
        const rect = e.target.getBoundingClientRect();

        setTooltip({
            visible: true,
            content: `${monthName} ${day}${getOrdinalSuffix(day)}: ${dp.toFixed(2)}% ±${std.toFixed(2)}%`,
            top: rect.top - containerRect.top - 30, // Calculate relative to container
            left: rect.left - containerRect.left + rect.width / 2, // Center horizontally within the container
        });
    };

    const handleMouseLeave = () => {
        setTooltip({ visible: false, content: '', top: 0, left: 0 });
    };

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
        return (
            <div ref={containerRef} style={{ position: 'relative' }}>  {/* Add ref to the container */}
                <h3 style={{ textAlign: 'center', marginTop: '40px', marginBottom: '20px' }}>Average daily returns across days and months</h3>
                
                <table style={{ width: '80%', margin: '0 auto', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th></th> {/* Empty header for the day numbers column */}
                            {monthNames.map((monthName, index) => (
                                <th key={index} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    {monthName}
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {Array.from({ length: 31 }, (_, dayIndex) => (
                            <tr key={dayIndex + 1}>
                                <td style={{ textAlign: 'right', paddingRight: '10px', fontWeight: 'bold' }}>
                                    {dayIndex + 1}
                                </td>
                                {monthNames.map((monthName, monthIndex) => {
                                    const dayData = data[(monthIndex+1).toString()]?.[dayIndex + 1] || { dp: 0, std: 0 }; // Get data for each month for the current day
                                    
                                    return (
                                        <td
                                            key={monthName}
                                            style={{
                                                width: `${boxSize}px`,
                                                height: `${boxSize}px`,
                                                backgroundColor: dayData.dp > 0 ? '#079a81' : dayData.dp < 0 ? '#f33645' : 'transparent',
                                                color: 'white',
                                                textAlign: 'center',
                                                borderRadius: '5px',
                                                position: 'relative',
                                                cursor: 'pointer',
                                                border: '1px solid #ddd',
                                                padding: '0',
                                                fontSize: `${boxSize * 0.3}px`,  // Set the font size dynamically to 70% of the box size
                                                lineHeight: `${boxSize}px`,  // Ensure the text is vertically centered
                                            }}
                                            onMouseEnter={(e) =>
                                                handleMouseEnter(e, monthNames[monthIndex], dayIndex + 1, dayData.dp, dayData.std)
                                            }
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            {dayData.dp !== 0 ? dayData.dp.toFixed(2) : ''}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>

                    
                </table>

                <h3 style={{ textAlign: 'left', marginBottom: '30px', marginLeft: '10%' }}></h3>

                {/* Tooltip display */}
                {tooltip.visible && (
                    <div
                        style={{
                            position: 'absolute',
                            top: `${tooltip.top}px`,
                            left: `${tooltip.left}px`,
                            transform: 'translateX(-50%)',
                            backgroundColor: '#333',
                            color: '#fff',
                            padding: '10px 10px',
                            borderRadius: '5px',
                            zIndex: 1000,
                            fontSize: '12px',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {tooltip.content}
                        <div
                            style={{
                                position: 'absolute',
                                top: '100%',
                                left: '50%',
                                marginLeft: '-5px',
                                borderWidth: '5px',
                                borderStyle: 'solid',
                                borderColor: '#333 transparent transparent transparent',
                            }}
                        ></div>
                    </div>
                )}
            </div>
        );    
    }

    return (
        <div ref={containerRef} style={{ position: 'relative' }}>  {/* Add ref to the container */}
            <h3 style={{ textAlign: 'left', marginTop: '40px', marginBottom: '20px', marginLeft: '10%' }}>Average daily returns across days and months</h3>
            
            <table style={{ width: '80%', margin: '0 auto', borderCollapse: 'collapse' }}>
                <tbody>
                    {Object.keys(data).map((month, index) => (
                        <tr key={month}>
                            <td style={{ textAlign: 'right', paddingRight: '10px', fontWeight: 'bold' }}>
                                {monthNames[index]}
                            </td>
                            {data[month].map((dayData, dayIndex) => {
                                if (dayIndex === 0) return null; // Skip non-existing days

                                return (
                                    <td
                                        key={dayIndex}
                                        style={{
                                            width: `${boxSize}px`,
                                            height: `${boxSize}px`,
                                            backgroundColor: dayData.dp > 0 ? '#079a81' : dayData.dp < 0 ? '#f33645' : 'transparent',
                                            color: 'white',
                                            textAlign: 'center',
                                            borderRadius: '5px',
                                            position: 'relative',
                                            cursor: 'pointer',
                                            border: '1px solid #ddd',
                                            padding: '0',
                                            fontSize: `${boxSize * 0.3}px`,  // Set the font size dynamically to 70% of the box size
                                            lineHeight: `${boxSize}px`,  // Ensure the text is vertically centered
                                        }}
                                        onMouseEnter={(e) =>
                                            handleMouseEnter(e, monthNames[index], dayIndex, dayData.dp, dayData.std)
                                        }
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        {dayData.dp !== 0 ? dayData.dp.toFixed(2) : ''}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th></th>
                        {Array.from({ length: 31 }, (_, i) => (
                            <th key={i + 1} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                {i + 1}
                            </th>
                        ))}
                    </tr>
                </tfoot>
            </table>

            <h3 style={{ textAlign: 'left', marginBottom: '30px', marginLeft: '10%' }}></h3>

            {/* Tooltip display */}
            {tooltip.visible && (
                <div
                    style={{
                        position: 'absolute',
                        top: `${tooltip.top}px`,
                        left: `${tooltip.left}px`,
                        transform: 'translateX(-50%)',
                        backgroundColor: '#333',
                        color: '#fff',
                        padding: '10px 10px',
                        borderRadius: '5px',
                        zIndex: 1000,
                        fontSize: '12px',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {tooltip.content}
                    <div
                        style={{
                            position: 'absolute',
                            top: '100%',
                            left: '50%',
                            marginLeft: '-5px',
                            borderWidth: '5px',
                            borderStyle: 'solid',
                            borderColor: '#333 transparent transparent transparent',
                        }}
                    ></div>
                </div>
            )}
        </div>
    );
    
};

export default Calendar;
