// src/components/PrevDayChartCard.js

import React from 'react';
import { Line } from 'react-chartjs-2';
import { cardChartStyle, infoIconStyle } from '../styles/styles';
import Tooltip from './Tooltip';
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler, // Import Filler for area fill
    Tooltip as ChartTooltip,
    Legend,
} from 'chart.js';

// Register chart.js components including Filler for area charts
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, ChartTooltip, Legend, Filler);

const PrevDayChartCard = ({ prev_days, tooltip, tooltipVisible, setTooltipVisible }) => {
    const labels = prev_days.map((dayData) => `${dayData?.month}/${dayData?.day}`);
    const realDpValues = prev_days.map((dayData) => dayData?.real_dp);
    const predDpAllValues = prev_days.map((dayData) => dayData?.pred_dp_all);
    const predDpAllStdValues = prev_days.map((dayData) => dayData?.pred_dp_all_std);

    // Calculate the upper and lower bounds for the shaded area
    const upperBound = predDpAllValues.map((mean, index) => mean + predDpAllStdValues[index]);
    const lowerBound = predDpAllValues.map((mean, index) => mean - predDpAllStdValues[index]);

    // Determine the min and max values from the data
    const realDpMin = Math.min(...realDpValues);
    const realDpMax = Math.max(...realDpValues);
    const lowerBoundMin = Math.min(...lowerBound);
    const upperBoundMax = Math.max(...upperBound);

    // Add padding to the min and max values
    const paddingFactor = 0.3; // 10% padding
    const yMin = Math.min(realDpMin, lowerBoundMin) * (1 + paddingFactor);
    const yMax = Math.max(realDpMax, upperBoundMax) * (1 + paddingFactor);

    const data = {
        labels,
        datasets: [
            {
                label: 'Real Return',
                data: realDpValues,
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: false,
                tension: 0.1,
            },
            {
                label: 'Mean Return',
                data: predDpAllValues,
                borderColor: 'rgba(255, 206, 86, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: false,
                tension: 0.1,
            },
            {
                label: 'Upper Bound',
                data: upperBound,
                borderColor: 'rgba(0, 123, 255, 0)', // Make the line for upper bound invisible
                backgroundColor: 'rgba(0, 123, 255, 0.2)', // Semi-transparent blue for the area fill
                fill: '+1', // Fills from this dataset to the next
                tension: 0.1,
            },
            {
                label: 'Lower Bound',
                data: lowerBound,
                borderColor: 'rgba(0, 123, 255, 0)', // Make the line for lower bound invisible
                backgroundColor: 'rgba(0, 123, 255, 0.2)', // Semi-transparent blue for the area fill
                fill: '+1', // Fills from this dataset to the bottom of the chart
                tension: 0.1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,  // Important to allow chart to fill container
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false,
            },
            legend: {
                display: false,
            },
        },
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
        },
        scales: {
            x: {
                title: {
                    display: true,

                },
            },
            y: {
                min: yMin, // Use padded min value
                max: yMax, // Use padded max value
                title: {
                    display: true,
                    text: 'Daily Return %'
                },
            },
        },
    };

    return (
        <div style={{ ...cardChartStyle, display: 'flex', flexDirection: 'column' }}>
            <img
                src="/ic_info.svg"
                alt="info"
                style={infoIconStyle}
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}
            />
            <Tooltip text={tooltip} isVisible={tooltipVisible} />
            <div style={{ flex: 1, marginTop:'30px' }}>
                <Line data={data} options={options} />
            </div>
        </div>
    );
};

export default PrevDayChartCard;
