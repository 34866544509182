class EventManager {
  constructor() {
    if (!EventManager.instance) {
      this.handlers = {}; // Store all event handlers in a map
      EventManager.instance = this;
    }
    return EventManager.instance;
  }

  // Register event listener for a specific event
  register(event, handler) {
    if (!this.handlers[event]) {
      this.handlers[event] = [];
    }
    this.handlers[event].push(handler);
  }

  // Unregister event listener for a specific event
  unregister(event, handler) {
    if (this.handlers[event]) {
      this.handlers[event] = this.handlers[event].filter(h => h !== handler);
    }
  }

  // Trigger event with data
  trigger(event, data = null) {
    if (this.handlers[event]) {
      this.handlers[event].forEach(handler => handler(data));
    }
  }
}

// Ensure a singleton instance
const instance = new EventManager();
Object.freeze(instance);

export default instance;
