import React, { useState, useEffect } from 'react';
import axios from 'axios';
import StrategyStatsCard from '../components/StrategyStatsCard'; 
import DateRangePicker from '../components/DateRangePicker'; 
import LineChartComponent from '../components/LineChart';
import { BASE_URL } from '../config';

const inputStyle = {
    padding: '15px',
    fontSize: '18px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    width: '100%',
    fontFamily: '-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif'
};

// Define fixed symbol groups
const symbolGroups = {
    "AAPL": ["NASDAQ:AAPL"],
    "FAANG": ["NASDAQ:AAPL", "NASDAQ:NFLX", "NASDAQ:GOOG", "NASDAQ:META", "NASDAQ:AMZN"],
    "Magnificent 7": ["NASDAQ:AAPL", "NASDAQ:NFLX", "NASDAQ:GOOG", "NASDAQ:META", "NASDAQ:AMZN", "NASDAQ:MSFT", "NASDAQ:NVDA", "NASDAQ:TSLA"],
    "Banking Stocks": ["NYSE:JPM", "NYSE:BAC", "NYSE:C"],
    "Energy Stocks": ["NYSE:XOM", "NYSE:CVX", "NYSE:SLB"],
    "Crypto": ['BINANCE:BTCUSDT', 'BINANCE:ETHUSDT', 'BINANCE:BNBUSDT', 'BINANCE:XRPUSDT', 'BINANCE:SOLUSDT', 'BINANCE:LTCUSDT', 'BINANCE:ADAUSDT']
};

const Strategy = () => {
    const [strategyData, setStrategyData] = useState(null);
    const [baselineData, setBaselineData] = useState([]); // Initialize baselineData state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredStrategy, setFilteredStrategy] = useState([]);
    const [filteredBaseline, setFilteredBaseline] = useState([]); // Initialize filteredBaseline state
    const [selectedGroup, setSelectedGroup] = useState("FAANG"); // Default symbol group

    const symbols = symbolGroups[selectedGroup]; // Get symbols based on the selected group

    const isValidDate = (d) => d instanceof Date && !isNaN(d);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.post(`${BASE_URL}/strategy`, { symbols });
                console.log(symbols);
                const { strategy, baseline, strategy_dd, strategy_sr, strategy_dd_dp, baseline_dd_dp } = response.data;

                const firstValue = strategy[0].value;
                const lastValue = strategy[strategy.length - 1].value;
                const balancePercentChange = ((lastValue - firstValue) / firstValue) * 100;

                setStrategyData({
                    daysTraded: strategy.length,
                    yearsTraded: (strategy.length / 365).toFixed(1),
                    currentBalance: Math.round(strategy[strategy.length - 1].value),
                    maxDrawdown: Math.round(strategy_dd),
                    maxDrawdownPercent: strategy_dd_dp, // Use strategy_dd_dp for the drawdown percentage
                    sharpeRatio: strategy_sr,
                    balanceHistory: strategy,
                    baseline_dd_percent: baseline_dd_dp, // Add baseline drawdown percent for future use,
                    balancePercentChange: balancePercentChange,
                });

                setBaselineData(baseline); // Set the baseline data in state

                setStartDate(new Date(strategy[0].datetime));
                setEndDate(new Date(strategy[strategy.length - 1].datetime));
                setFilteredStrategy(strategy);
                setFilteredStrategy(strategy);
                setFilteredBaseline(baseline); // Set filtered baseline data initially
            } catch (err) {
                console.error('Error fetching data:', err);
                setError('Failed to load strategy data.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [selectedGroup]);

    useEffect(() => {
        if (isValidDate(startDate) && isValidDate(endDate) && strategyData && baselineData) {
            // Filter strategy data
            const filtered = strategyData.balanceHistory.filter(dataPoint => {
                const date = new Date(dataPoint.datetime);
                return date >= startDate && date <= endDate;
            });
            setFilteredStrategy(filtered);

            // Filter baseline data
            const filteredBaseline = baselineData.filter(dataPoint => {
                const date = new Date(dataPoint.datetime);
                return date >= startDate && date <= endDate;
            });
            setFilteredBaseline(filteredBaseline);
        } else if (strategyData) {
            setFilteredStrategy(strategyData.balanceHistory);
            setFilteredBaseline(baselineData); // If no filtering, set full baseline data
        }
    }, [startDate, endDate, strategyData, baselineData]);


    // Calculate min and max values for the Y-axis scale
    const minBalance = Math.min(...filteredStrategy.map(dataPoint => dataPoint.value));
    const maxBalance = Math.max(...filteredStrategy.map(dataPoint => dataPoint.value));

    // Build the chart data from the filtered strategy
    const chartData = filteredStrategy.map(dataPoint => ({
        date: new Date(dataPoint.datetime).toISOString().split('T')[0], // Format the date as YYYY-MM-DD
        balance: parseFloat(dataPoint.value.toFixed(2)) // Format balance to 2 decimal places
    }));


    const baselineChartData = baselineData.map(dataPoint => ({
        date: new Date(dataPoint.datetime).toISOString().split('T')[0], // Format the date as YYYY-MM-DD
        balance: parseFloat(dataPoint.value.toFixed(2)) // Format balance to 2 decimal places
    }));


    // Helper function to format numbers with commas, fallback to 0 if value is not a number
    const formatNumber = (num) => (num ? num.toLocaleString() : '0');

    if (loading) {
        return (
            <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                flexDirection: 'column', 
                height: '100vh' 
            }}>
                <div className="spinner" style={{
                    border: '4px solid rgba(0, 0, 0, 0.1)', 
                    borderRadius: '50%', 
                    borderTop: '4px solid #3498db', 
                    width: '50px', 
                    height: '50px', 
                    animation: 'spin 2s linear infinite'
                }}></div>
                <p style={{ 
                    marginTop: '20px', 
                    fontSize: '18px', 
                    color: '#666', 
                    fontFamily: '-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif'
                }}>
                    Loading strategy data, please wait...
                </p>
                <style>
                    {`
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                    `}
                </style>
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100vh', 
                flexDirection: 'column', 
                textAlign: 'center',
                color: 'red'
            }}>
                <p style={{ 
                    fontFamily: '-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif',
                    fontSize: '18px'
                }}>
                    {error}
                </p>
            </div>
        );
    }

    return (
        <div style={{ padding: '20px', fontFamily: '-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif' }}>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <h1 style={{ fontSize: '48px', textAlign: 'left', width: '80%', margin: '0 auto', padding: '20px' }}>Profit Score Based Strategy</h1>
                <p style={{ fontSize: '18px', marginTop: '10px', color: '#666', textAlign: 'left', width: '80%', margin: '0 auto', lineHeight: '1.3' }}>
                    This strategy monitors the profit score of multiple symbols to identify the best candidates for the next trading day.<br/>
                    It takes a long position on the symbol with the highest score using the current available balance, entering at the end of the current day and holding until the close of the next day.<br/>
                    The objective is to minimize risk while achieving consistent, long-term profits through systematic trades.<br/><br/>
                    The baseline for comparison is a buy-and-hold strategy where the initial balance is equally split between all the symbols used in the strategy.<br/>Each symbol receives an even weight, and the performance is tracked based on holding these positions without any active trading.
                </p>


            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px', marginTop: '40px', gap: '50px', padding: '0 30px' }}>
                <StrategyStatsCard 
                    title="Days Traded" 
                    value={strategyData?.daysTraded} 
                    additionalInfo=''
                />
                <StrategyStatsCard 
                    title="Balance" 
                    value={
                        <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontSize: '60%', color: 'gray', marginRight: '5px', alignSelf: 'flex-start' }}>$</span>
                            {formatNumber(strategyData?.currentBalance)}
                        </span>
                    }
                    additionalInfo={`${strategyData?.balancePercentChange?.toFixed(2)}%`} 
                />
                <StrategyStatsCard 
                    title="Max Drawdown" 
                    value={
                        <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ fontSize: '60%', color: 'gray', marginRight: '5px', alignSelf: 'flex-start' }}>$</span>
                            {formatNumber(strategyData?.maxDrawdown)}
                        </span>
                    }
                    additionalInfo={`${strategyData?.maxDrawdownPercent?.toFixed(2)}%`} 
                />
                <StrategyStatsCard 
                    title="Sharpe Ratio" 
                    value={strategyData?.sharpeRatio?.toFixed(2)} 
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',  }}>
                {/* Group Selector (Left-Aligned) */}
                <div style={{ flexBasis: '30%', marginLeft: '0px' }}>  {/* Ensure the group selector takes some width */}
                    <p style={{ marginBottom: '5px', fontSize: '16px' }}>Symbols Group</p>
                    <select
                        id="symbolGroup"
                        value={selectedGroup}
                        onChange={(e) => setSelectedGroup(e.target.value)} // Handle group change
                        style={{
                            padding: '15px',  // Match the padding of the date pickers
                            fontSize: '18px',  // Match the font size of the date pickers
                            borderRadius: '4px', // Same border radius as the date pickers
                            border: '1px solid #ccc',  // Border style to match the date pickers
                            width: '100%',  // Ensure it takes full width
                            fontFamily: '-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif'
                        }}>
                        {Object.keys(symbolGroups).map(group => (
                            <option key={group} value={group}>
                                {group}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Date Pickers (Right-Aligned) */}
                <div style={{ display: 'flex', gap: '20px', justifyContent: 'flex-end', flexBasis: '45%',  }}>  {/* Flexbox for aligning date pickers */}
                    <div>
                        <p style={{ marginBottom: '5px', fontSize: '16px' }}>Start Date</p>
                        <input
                            type="date"
                            value={isValidDate(startDate) ? startDate.toISOString().split('T')[0] : ''}
                            onChange={e => setStartDate(new Date(e.target.value))}
                            style={inputStyle} 
                        />
                    </div>
                    <div>
                        <p style={{ marginBottom: '5px', fontSize: '16px' }}>End Date</p>
                        <input
                            type="date"
                            value={isValidDate(endDate) ? endDate.toISOString().split('T')[0] : ''}
                            onChange={e => setEndDate(new Date(e.target.value))}
                            style={inputStyle} 
                        />
                    </div>
                </div>
            </div>


            <div style={{ width: '76%', margin: '5px auto', paddingTop: '10px' }}>
                <h3 style={{ fontSize: '24px', color: '#333', textAlign: 'left' }}>Equity Over Time</h3>
                <p style={{ fontSize: '14px', color: '#666', marginTop: '-20px' }}>
                    Simulated equity based on strategy with initial capital of 10,000$
                </p>
            </div>

            <div style={{ width: '80%', height: '600px', margin: '20px auto' }}>
                <LineChartComponent 
                    data={chartData} 
                    baselineData={baselineChartData} 
                    minBalance={minBalance} 
                    maxBalance={maxBalance} 
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', width: '80%', margin: '0 auto' }}>
                <button
                    style={{
                        backgroundColor: '#fcd72b',
                        border: 'none',
                        color: '#333',
                        padding: '10px 20px',
                        fontSize: '16px',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        marginBottom: '30px',
                        fontFamily: '-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif',
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        transition: 'background-color 0.3s ease'
                    }}
                    onMouseEnter={e => e.currentTarget.style.backgroundColor = '#e6c545'}
                    onMouseLeave={e => e.currentTarget.style.backgroundColor = '#fcd72b'}
                >
                    <img
                        src='ic_alpaca.svg'
                        alt="Alpaca icon"
                        style={{ width: '24px', height: '24px', marginRight: '8px' }}
                    />
                    Copy with Alpaca
                </button>
            </div>
        </div>
    );
};

export default Strategy;
